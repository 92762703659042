<template>
  <v-text-field
    type="password"
    v-model="password"
    autocomplete="off"
    :rules="rules"
    :label="i18n(label)"
    :outlined="outlined"
    :readonly="readonly"
    :autofocus="autofocus"
    :prepend-inner-icon="icon"
    @keyup.enter="$emit('on-enter', $event)"
  />
</template>

<script>
import { passRules } from '@/rules'

const onlyString = (str) => String(str || '').trim()

export default {
  props: {
    icon: { default: 'mdi-shield-lock-outline' },
    value: {},
    rules: { default: () => passRules },
    label: { default: 'field.password' },
    outlined: { default: false },
    readonly: { default: false },
    autofocus: { default: false }
  },

  data () {
    return {
      password: onlyString(this.value)
    }
  },

  watch: {
    password () {
      const password = onlyString(this.password)
      const changed = password !== onlyString(this.value)
      if (changed) this.$emit('input', password)
    },

    value () {
      const value = onlyString(this.value)
      const changed = value !== onlyString(this.password)
      if (changed) this.password = value
    }
  }
}
</script>
